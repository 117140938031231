<script>
import { onMounted } from "vue";
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    onMounted(() => {
      store.dispatch("getShortList");
      store.dispatch("getHomeIndex");
      store.dispatch("getIndicesList");
    });
  },
};
</script>
<template>
  <header class="sg_hd">
    <div class="container">
      <div class="row">
        <div class="col-sm-6">
          <h2>
            <a class="campeche" href="https://campeche.gob.mx/"
              >Gobierno de Campeche</a>
            
          </h2>
        </div>
        <div class="col-sm-6">
          <nav>
            <ul>
              <li><router-link :to="{ name: 'Home' }">Inicio</router-link></li>
              <li>
                <router-link :to="{ name: 'Categories' }"
                  >Estructura</router-link
                >
              </li>
              <li>
                <router-link :to="{ name: 'About' }"
                  >Sobre el SINGE</router-link
                >
              </li>
              <!-- <li><router-link to="/test">Test</router-link> </li>-->
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </header>
  <div class="sg_nameapp">
    <h2><strong>SINGE</strong> | Sistema de Indicadores de Género</h2>
  </div>
  <router-view />
  <section class="sg_smf">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p class="text-center">
           
          </p>
        </div>
      </div>
    </div>
  </section>
  <footer>
    <div class="container">
      <div class="row mb-4">
        <div class="col-12">
          <h2>
            <a href="https://campeche.gob.mx/" class="campeche_w">Gobierno de Campeche
              </a>
          </h2>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-8">
          
          <p><strong>Teléfono:</strong>  
            (981)8119200   </p>

          <p>
            C.P. 24000<br>         San Francisco de Campeche </p>
        </div>
        <div class="col-sm-4">
          <ul class="mt-4">
            <li><router-link to="/acerca-de">Acerca del SINGE</router-link></li>
            
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>
